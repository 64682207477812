.testimonial-box {
    background-color: #FFFFFF;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 30px;
    margin: 20px;
    flex: 1 1 700px;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease;
}
  
.testimonial-box:hover {
    transform: scale(1.05);
}
  
.star-image {
    width: 80px;
    align-self: center;
}
  
.testimonial-box h3 {
    font-size: 24px;
    font-weight: 500;
    color: #071952;
    margin: 10px 0;
    text-align: center;
}
  
.testimonial-box p {
    font-size: 1.25em;
    color: #666666;
    margin: 10px 0;
    text-align: left;
    flex-grow: 1;
}
  
.read-more {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    margin-left: 5px;
}
  
.person-info {
    display: flex;
    align-items: center;
    margin-top: 20px;
}
  
.person-image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}
  
.person-details {
    margin-left: 15px;
}
  
.name {
    font-size: 18px;
    font-weight: 500;
    color: #071952;
    margin: 0;
}
  
.company {
    font-size: 16px;
    color: #666666;
    margin: 0;
    font-style: italic;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    position: relative;
}
  
.close-button {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}
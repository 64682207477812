/* General Reset */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Base Styles */
body {
  font-family: 'Montserrat', sans-serif;
  background-color: #EBF4F6;
}

.about-page {
  margin-top: 0px;
}

.mission-story-container {
  height: 100%;
}

/* Our Story Section */
.our-story-container {
  margin-left: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5%;
  width: 90%;
}

.our-story-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.story-image {
  height: 120%;
  padding-top: 10%;
  width: 140%;
}

.our-story-statement {
  flex: 1;
  padding: 20px;
  color: #333;
  text-align: justify;
}

.our-story-statement h1 {
  text-align: left;
  font-size: 3rem;
  color: #071952;
  margin-bottom: 20px;
}

.our-story-statement p {
  margin-bottom: 20px;
  font-size: 1.25rem;
}

/* Mission Section */
.mission-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 60px;
  margin: 40px auto;
  width: 90%;
}

.mission-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.mission-image {
  padding-left: 15%;
  width: 140%;
}

.mission-statement {
  flex: 1;
  padding: 20px;
  color: #333;
  text-align: justify;
}

.mission-statement h1 {
  text-align: left;
  font-size: 3rem;
  color: #071952;
  margin-bottom: 20px;
}

.mission-statement p {
  margin-bottom: 20px;
  font-size: 1.25rem;
}

.mission-statement p:last-child {
  margin-bottom: 0;
}

/* Team Image Container */

.team-container {
  background-color: #f8f9f4;;
}

.team-title h1 {
  text-align: center;
  font-size: 3rem;
  color: #071952;
  padding-top: 30px;
  margin-top: 60px;
  margin-bottom: 20px;
}

.team-title h4 {
  text-align: center;
  font-size: 2.6em;
  margin-bottom: 40px;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 140px;
  gap: 60px;
  justify-content: center;
}

/* Team Card */
.team-card {
  background-color: #fbfbfb;
  background: #e0e0e0;
  height: 500px;
  border-radius: 50px;
  box-shadow: 41px 41px 55px #bebebe,
             -41px -41px 55px #ffffff;;
  padding-top: 5%;
  gap: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  justify-content: flex-start;
}

.team-card:hover {
  transform: translateY(-5px); /* Lift effect */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

/* Team Image */
.team-card-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;
  margin-top: 40px;
}

.team-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Team Info */
.team-card-info {
  text-align: center;
}

.team-card-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
}

.team-card-position {
  font-size: 1.25rem;
  font-style: italic;
  color: #777777;
}

.team-card-icons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.icon-link {
  font-size: 2.5rem; 
  color: #071952; 
  transition: color 0.3s ease, transform 0.3s ease; 
}

.icon-link:hover {
  color: #0077b5; 
  transform: scale(1.2); 
}

.advisors-container {
  background-color: #EBF4F6; /* Light background */
  padding: 60px 5%; /* Spacing around the section */
  text-align: center;
}

.advisors-title h1 {
  font-size: 3rem;
  color: #071952;
  margin-bottom: 20px;
  letter-spacing: 1px;
  border-bottom: 3px solid #071952; /* Underline effect */
  display: inline-block;
  padding-bottom: 5px;
}

/* Advisors Grid */
.advisors-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Responsive grid */
  gap: 20px;
  margin-top: 40px;
}

/* Advisor Card */
.advisor-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}

.advisor-card:hover {
  transform: translateY(-5px); /* Slight lift */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15); /* Enhanced shadow */
}

.advisor-card h3 {
  font-size: 1.5rem;
  color: #071952;; /* Green color for names */
  margin-bottom: 10px;
}

.advisor-card p {
  font-size: 1.25rem;
  color: #555; /* Softer text for details */
  line-height: 1.6;
  margin: 5px 0;
}

.advisor-icons {
  margin-top: 10px;
  text-align: left;
}

.icon-link {
  font-size: 1.5rem; /* Icon size */
  color: #0077b5; /* LinkedIn blue color */
  transition: color 0.3s ease, transform 0.3s ease;
  margin-right: 10px; /* Space between icons if needed */
}

.icon-link:hover {
  color: #005983; /* Darker blue on hover */
  transform: scale(1.2); /* Slight zoom effect on hover */
}

/* Interns Section */

.interns-container {
  background-color: #f9f9f9;
  padding: 60px 5%;
  text-align: center;
}

.interns-title h1 {
  font-size: 2.5rem;
  color: #071952;
  margin-bottom: 20px;
}

.interns-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.intern-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.intern-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.intern-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.intern-info {
  text-align: center;
}

.intern-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333333;
  margin-bottom: 5px;
}

.intern-position {
  font-size: 1.25rem;
  font-style: italic;
  color: #777777;
  margin-bottom: 10px;
}

.intern-icons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.icon-link {
  font-size: 1.5rem;
  color: #071952;
  transition: color 0.3s ease, transform 0.3s ease;
}

.icon-link:hover {
  color: #0077b5;
  transform: scale(1.2);
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
  .advisor-card h3 {
    font-size: 1.25rem;
  }

  .advisor-card p {
    font-size: 0.9rem;
  }
}

/* Founders Background */
.founders-background {
  background-color: #088395;
  color: white;
  padding: 40px 5%;
  margin-top: 40px;
  line-height: 1.6;
  font-size: 1.25rem;
}

/* Calseed Section */
.calseed-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 40px 5%;
}

.calseed-section img {
  width: auto;
  max-width: 200px;
  height: auto;
}



/* mobile device styling*/

@media screen and (max-width: 600px) {
  .our-story-container {
    flex-direction: column;
    text-align: left;
  }

  .our-story-statement {
    text-align: left;
  }

  .our-story-statement h1 {
    font-size: 2rem;
  }

  .our-story-statement p {
    font-size: 1.15rem;
  }

  .our-story-image-container {
    align-items: center;
  }

  .story-image {
    align-items: center;
    height: 100%;
    width: 100%;
    padding-right: 15%;
  }

  /* mission statement stuff */

  .mission-container {
    flex-direction: column;
    text-align: left;
  }

  .mission-statement {
    text-align: left;
  }

  .mission-statement h1 {
    font-size: 2rem;
  }

  .mission-statement p {
    font-size: 1.15rem;
  }

  .mission-image-container {
    align-items: center;
  }

  .mission-image {
    align-items: center;
    height: 100%;
    width: 100%;
  }

  /* team stuff */

  .team-title h1 {
    font-size: 2rem;
  }

  .team-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  .team-card {
    height: 350px;
    box-shadow: 21px 21px 43px #bebebe,
             -21px -21px 43px #ffffff;;
  }

  .team-card-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }

  /* calseed section */

  .calseed-section img {
    max-width: 150px;
  }
}

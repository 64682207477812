.spacer {
  margin-top: 90px;
}

.resources-header-section {
    background-color: #088395;
    font-size: 3em;
    padding: 2rem;
    text-align: left;
    font-family: 'Syne', sans-serif;
    color: white;
    margin-bottom: 4rem;
    margin-top: 4rem;
  }
  
  .resources-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    gap: 2%;
    padding: 1rem;
    justify-items: center;
  }
  
  .resource-box {
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 750px;
    text-decoration: none;
    color: black;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  
  .resource-box:hover {
    transform: scale(1.05); /* Increase size on hover */
  }
  
  .resource-box img {
    width: 100%;
    height: auto;
    object-fit: cover;
    min-height: 200px;
    aspect-ratio: 16 / 9;
  }

  .resource-info {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .resource-info h1 {
    font-size: 1.25em;
    margin: 0;
  }
  
  .resource-info .date-posted {
    font-size: 1.25em;
    color: gray;
  }
  
  .resource-info p {
    font-size: 1.25em;
    margin: 0;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .pagination button {
    padding: 0.5rem 1rem;
    background-color: #088395;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .pagination button:hover {
    background-color: #065f73;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .pagination span {
    font-size: 1.2rem;
  }
  
  @media screen and (max-width: 900px) {
    .resources-header-section {
      font-size: 24px;
      text-align: center;
      padding: 15px;
    }
    
    .resources-container {
      flex-direction: column;
      align-items: center;
    }

    .resource-box {
      width: 90%; /* Adjust width for smaller screens */
      padding: 15px;
    }

    .resource-info h2 {
      font-size: 20px; /* Adjust font size for smaller screens */
      padding-left: 15px;
      padding-right: 15px;
    }
    
    .resource-info p, .resource-info .date-posted {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

@media screen and (max-width: 600px) {
  .resources-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .resource-box {
    width: 90%;
    margin: 0 auto;
  }

  .pagination {
    padding-top: 7%;
    gap: 0.5rem;
  }

}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import '@fortawesome/fontawesome-free/css/all.min.css';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

html, body {
  background-color: #EBF4F6;
  overflow-x: hidden;
}


.App {
  background-color: #EBF4F6;
  font-family: 'Montserrat', sans-serif;
}

.hero {
  position: relative;
  background: linear-gradient(to bottom right, #071952, #37B7C3);
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 5rem;
  min-height: 75vh;
}

.hero-title {
  padding-top: 6.25rem;
  text-align: center;
  font-size: 4rem;
  margin-bottom: 4.5rem;
  color: #ffffff;
}

.hero-title .gradient-text {
  font-weight: bold;
  background: linear-gradient(90deg, #32cd32, #a9f8a9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 2rem;
  padding-left: 0rem;
  padding-bottom: 6rem;
  overflow: visible;
  min-height: 65vh;
}

.hero-text {
  flex: 1;
  min-width: 0;
  padding-left: 5vw;
  color: #ffffff;
  font-size: 1.7vw;
  line-height: 1.2;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
}

.hero-text p {
  margin-bottom: 2vw;
}

.hero-text .cta-button {
  background: #32cd32;
  color: #ffffff;
  padding: 0.8rem 1.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  transition: background 0.3s ease;
}

.hero-text .cta-button:hover {
  background: #28a428;
}

.hero-globe {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible;
}

.hero-globe canvas {
  max-width: 100%;
  width: auto !important;
  height: auto !important;
  display: block;
}

/* hero responsiveness */




/* carousel css */

.split-carousel-container {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 2rem;
  align-items: center;
  padding: 2rem;
  max-width: 1600px;
  margin: 0 auto;
  position: relative;
}

.split-carousel-image-container, .split-carousel-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.split-carousel-text {
  text-align: left;
}

.split-carousel-text h2 {
  font-size: 3rem;
  line-height: 1.2;
  color: #071952;
}

.split-carousel-text h2 strong {
  font-weight: bold;
  background: linear-gradient(90deg, #32cd32, #a9f8a9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.split-carousel-image {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border: 6px solid #fff; 
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
}

.carousel-indicators-wrapper {
  grid-column: 1 / -1;  
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.carousel-indicators {
  display: flex;
  gap: 0.5rem;
}

.indicator {
  width: 10px;
  height: 10px;
  border: 2px solid #333;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
  transition: background 0.3s ease;
}

.indicator.current {
  background: #333;
}

/* dashboard solution stuff */

.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 90px;
  position: relative;
}

.dashboard-container .layer {
  position: absolute;
  border-radius: 10px;
}

.dashboard-container .layer.light-blue {
  background-color: #37B7C3;
  width: 800px;
  height: 550px;
  transform: rotate(-5.51deg);
  border-radius: 50px;
  z-index: 1;
}

.dashboard-container .layer.dark-blue {
  background-color: #088395;
  width: 800px;
  height: 550px;
  transform: rotate(5.51deg);
  border-radius: 50px;
  z-index: 2;
}

.dashboard-container .layer.black {
  background-color: #000;
  width: 713px;
  height: 435px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  border-radius: 15px;
}

.dashboard-container .dashboard-image {
  width: 700px;
  height: 422px;
  border-radius: 10px;
  z-index: 4;
}

/* Initial State: Off-screen */
.off-screen-left {
  transform: translateX(-50%);
  opacity: 0;
}

.off-screen-right {
  transform: translateX(50%);
  opacity: 0;
}

/* Final State: On-screen */
.on-screen {
  transform: translateX(0);
  opacity: 1;
}

.transition-all {
  transition: transform 1s, opacity 1s;
}

.section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 150px 20px;
  text-align: left;
  background-color: #EBF4F6;
  overflow: hidden;
  position: relative;
  margin-top: 80px;
  margin-bottom: 80px;
}

.section.reverse {
  flex-direction: row-reverse;
}

.section .text-content {
  flex: 1;
  padding: 20px;
}

.section .text-content h1 {
  font-size: 3rem;
  margin-bottom: 10px;
  font-weight: 500;
  color: #071952;
}

.section .text-content p {
  font-size: 1.75rem; 
  color: #071952;
}

.section .image-content {
  flex: 1;
  text-align: center;
  position: relative; /* Ensure layers are positioned correctly */
}

.section .image-container {
  position: relative; /* Ensure layers are positioned correctly */
  display: inline-block; /* Prevent container from stretching */
}

.section .image-content .dark-blue {
  position: absolute;
  top: -14%;
  left: 0%;
  border-radius: 50px; /* Default border-radius for layers */
}
.section .image-content .light-blue {
  position: absolute;
  top: -20%;
  left: 1%;
  border-radius: 50px; /* Default border-radius for layers */
}

.section .image-content .screen-layer-right {
  position: absolute;
  top: -1.5%;
  left: 9.2%;
  border-radius: 15px; /* Customizable border-radius for the screen layer */
}
.section .image-content .screen-layer-left {
  position: absolute;
  top: -2%;
  left: 9.2%;
  border-radius: 15px; /* Customizable border-radius for the screen layer */
}
.section .image-content img {
  max-width: 80%; /* Adjust size as needed */
  height: auto;
  border-radius: 10px; /* Optional: Adds rounded corners */
  position: relative; /* Ensure image is positioned relative to the layers */
}

.testimonial-section {
  background-color: #FFFFFF; /* White background for the testimonial section */
  padding: 50px 20px; /* Padding for the section */
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.testimonial-header h1 {
  font-size: 3rem;
  margin-bottom: 30px;
}

.testimonial-container {
  display: flex;
  justify-content: space-around; /* Space around the boxes */
  align-items: stretch; /* Align items to the start of the container */
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
  max-width: 1600px; /* Maximum width of the container */
}

.testimonial-box {
  background-color: #FFFFFF; /* White background for each box */
  border-radius: 52px; /* Rounded corners for each box */
  box-shadow: 34px 34px 67px #cccccc,
              -34px -34px 67px #f4f4f4;
  padding: 40px;
  min-height: 400px;
  margin: 20px;
  flex: 0 1 400px; /* Flex properties for responsive design */
  text-align: left;
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.testimonial-box:hover {
  transform: scale(1.05); /* Increase size on hover */
}

.testimonial-star img {
  max-width: 100%; /* Ensure the star image fits within the container */
  height: auto;
}

.testimonial-header {
  font-size: 24px;
  font-weight: 500;
  color: #071952;
  margin: 10px 0;
}

.testimonial-quote {
  font-size: 16px;
  color: #666666;
  margin: 10px 0;
  flex-grow: 1;
}

.testimonial-person img {
  width: 60px; /* Width of the circular image */
  height: 60px; /* Height of the circular image */
  border-radius: 50%; /* Make the image circular */
  object-fit: cover; /* Ensure the image covers the container */
  margin: 10px 0;
}

.testimonial-name {
  font-size: 18px;
  font-weight: 500;
  color: #071952;
  margin: 5px 0;
}

.testimonial-company {
  font-size: 16px;
  color: #666666;
}

/* Contact section styles */
.contact-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 20px;
  background: url('./images/contact-bg.png') no-repeat center center;
  background-size: cover;
  
}

.contact-left {
  flex: 1;
  color: white;
  padding: 20px;
  margin-bottom: 7%;
}

.contact-left h1 {
  font-size: 3rem;
  margin-bottom: 4%;
}

.contact-left p {
  font-size: 2.75rem;
}

.contact-right {
  flex: 1;
  background: white;
  padding: 20px;
  border-radius: 20px;
  border-width: 5px;
  border-color: black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #071952;
}

.contact-form {
  text-align: center;
}

.contact-form select,
.contact-form input,
.contact-form textarea {
  width: 90%;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: 'Montserrat';
  text-align: center;
  background-color: #f5f5f5;
}

.contact-form textarea {
  resize: vertical;
}

.submit-button {
  background: #071952;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s, transform 0.5s;
  font-family: 'Montserrat';
}

.submit-button:hover {
  background-color: white;
  color: #071952;
  transform: scale(1.25);
}

/* Mobile view */
@media screen and (max-width: 600px) {

  /* mobile hero display */

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 2rem;
  }

  .hero-content {
    min-height: 700px;
  }

  .hero-globe {
    padding-left: 5%;
    position: absolute;
    margin-bottom: -5rem;
  }

  .hero-text {
    margin-top: 30%;
    position: absolute;
    text-align: center;
    width: 85%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem; 
    text-align: center;
  }

  /* mobile carousel styling */

  .split-carousel-container {
    display: flex;
    flex-direction: column;
  }

  .split-carousel-image {
    height: 375px;
  }

  .split-carousel-text h2 {
    font-size: 2rem;
    line-height: 1.2;
  }

  /* testimonial section mobile styling */


  /* contact section mobile styling  */
  .contact-section {
    display: flex;
    flex-direction: column;
  }

  .contact-left {
    text-align: center;
  }

  .contact-left h1 {
    font-size: 2rem;
  }

  .contact-left p {
    font-size: 1.75rem;
  }
}









/* 

dashboards solutions mobile styling; why was it written here??
every time i try to move it to solutions.css, app breaks :D

*/

@media screen and (max-width: 768px) {
  .section {
    flex-direction: column;
    text-align: left;
    padding: 20px;
  }

  .section .text-content {
    padding: 10px 0;
    text-align: left;
  }

  .section .image-content {
    margin-top: 20px;
    text-align: center;
  }

  .section .image-content img {
    max-width: 100%;
    height: auto;
  }

  .section.reverse {
    flex-direction: column;
  }
}